
import { Component, Mixins } from 'vue-property-decorator';

import AllChannelsSection from '@/modules/di-lite/components/all-channels/all-channels-section.vue';
import MarketSection from '@/modules/di-lite/components/market/market-section.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DiLiteHeader from '@/modules/di-lite/components/di-lite-header.vue';
import DiLiteOtelMixin from '@/modules/open-telemetry/mixins/di-lite/di-lite-page-otel-logs.mixin';

@Component({
    components: {
        PageWrapper,
        DiLiteHeader,
        AllChannelsSection,
        MarketSection,
    },
})
export default class DiLitePage extends Mixins(DiLiteOtelMixin) {}
