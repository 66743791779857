

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class DIUpgradeCard extends Vue {
    @inject(ConfigServiceS) private configService!: ConfigService;
    @inject(UserServiceS) private userService!: UserService;
}
