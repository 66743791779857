
import { Component, Vue, Prop } from 'vue-property-decorator';
import IIndicator from '../../interfaces/indicator.interface';

@Component
export default class DayIndicatorTooltip extends Vue {
    @Prop({
        type: Array,
        default: null,
    })
    indicators!: IIndicator[] | null;
}
