

import { Prop, Vue } from 'vue-property-decorator';
import { ChartData, ChartOptions } from 'chart.js';
import { capitalize } from 'lodash';
import Component from 'vue-class-component';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import BmlDataModel from '@/modules/di-lite/models/market-bml-data.model';
import BMLGraphTooltip from './bml-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph,
        CustomTooltip,
        BMLGraphTooltip,
    },
})
export default class BMLGraph extends Vue {
    @Prop({ type: Object as () => BmlDataModel, default: () => {} })
    data!: BmlDataModel;

    @Prop({ type: Boolean })
    loading!: boolean;

    private colorList: { [key: string]: string }= {
        beat: '#FED51C',
        meet: '#19BE81',
        lose: '#EA8583',
    };

    private get dataEntries() {
        return Object.entries(this.data);
    }

    private get valueSum() {
        return Object.values(this.data).reduce((p, c) => p + c, 0);
    }

    get legend() {
        const { valueSum } = this;
        return this.dataEntries
            .map(([key, value]) => ({
                color: this.colorList[key],
                label: key,
                percent: value ? ((value / valueSum) * 100).toFixed(2) : -1,
            }));
    }

    get chartData(): ChartData {
        const { valueSum } = this;
        const entries = Object.entries(this.data);
        const percentValues = entries
            .map(([_, v]) => (v / valueSum) * 100);
        const labels = entries
            .map(([key]) => capitalize(key));
        const colors = entries.map(([key]) => this.colorList[key]);

        return {
            labels,
            datasets: [{
                backgroundColor: colors,
                barThickness: 22,
                minBarLength: 5,
                data: percentValues,
                barPercentage: 1,
            }],
        };
    }

    get options(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: 4,
            },
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        };
    }
}
