

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';

@Component
export default class CIUpgradeCard extends Vue {
    @inject(ConfigServiceS) configService!: ConfigService;
}
