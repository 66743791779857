
import Component from 'vue-class-component';
import { Prop, Watch, Vue } from 'vue-property-decorator';

@Component({})
export default class RootCauseAnalysisTooltip extends Vue {
    @Prop({
        type: Array,
        default: () => ([]),
    })
    private legends!: string[];

    @Prop({
        type: Object,
        default: () => ({}),
    })
    private tooltipProps!: any;

    isLeft = false;

    get title() {
        return this.legends[this.tooltipProps.hoverIndex];
    }

    get value() {
        return this.tooltipProps.chartData.datasets[0]
            .data[this.tooltipProps.hoverIndex];
    }

    get percent() {
        return Math.round(this.value / this.maxValue * 100);
    }

    get maxValue() {
        return this.tooltipProps.chartData.datasets[0].data
            .reduce((c: number, p: number) => p + c, 0);
    }

    @Watch('tooltipProps')
    updateSide() {
        // @ts-ignore
        let { left: x } = this.$refs.container.parentNode.style;
        x = parseFloat(x);

        this.isLeft = x < 80;
    }
}
