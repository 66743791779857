
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class RootCauseAnalysisTooltip extends Vue {
    @Prop({ type: Array, default: () => ([]) })
    private legends!: Record<string, string>[];

    @Prop({ type: Object, default: () => ({}) })
    tooltipProps!: Record<string, any>;

    get currentData() {
        return this.legends[this.tooltipProps.hoverIndex];
    }

    isLeft = false;

    @Watch('tooltipProps')
    updateSide() {
        // @ts-ignore
        let { left: x } = this.$refs.container.parentNode.style;
        x = parseFloat(x);

        this.isLeft = x < 80;
    }
}
